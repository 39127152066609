import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Silkscreen Printing",
  "description": "Using a stencil to print colors on plastics.",
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["Hot-Stamping", "Hydrographics", "Spray-Painting"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Silkscreen printing is a type of stencil printing, which requires a flat working surface. The stencils provided are designed specifically for one color, and the process of painting on top of the stencils is repeated based on the layer configuration until the desired color and design is obtained. These are commonly done on plastics, and not for metals.`}</p>
    <p>{`The degree of the silkscreen thread fineness is measured by the mesh count. The mesh count itself is the number of polyester threads that intersect each other within one square inch. NexPCB uses a `}<strong parentName="p">{`200T to 300T silkscreen mesh`}</strong>{`. The higher the value of T is, the finer the threads are. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      